import {
	AOFL_PRODUCT_NAMES,
	EVENT_NAMES,
	QUERY_PARAM_KEYS,
	LOCAL_STORAGE_KEYS,
	COOKIES
} from '$lib/utils/constants';
import {
	getJSPath,
	isCustomTrackedElement,
	hashElementPath,
	trackedElems
} from '$lib/utils/click-tracking';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '$lib/services/api';
import { getDeviceId } from '$lib/utils/device-id';
import * as Sentry from '@sentry/sveltekit';
import Cookies from 'js-cookie';
import { get } from 'svelte/store';
import { app } from '$lib/stores/app/store';

/**
 *
 */
class AnalyticsService {
	/**
	 * Handles the visibility change event.
	 * @param {Event} event - The visibility change event.
	 */
	static visibilityChangeHandler(event) {
		if (document.visibilityState === 'hidden') {
			// TODO: track app exit
		} else if (document.visibilityState === 'visible') {
			// TODO: track app enter
		}
	}

	/**
	 * Handles click tracking for analytics.
	 * @param {Event} e - The click event.
	 * @returns {Promise<void>} - A promise that resolves when the event is tracked.
	 */
	static async clickTrackingHandler(e) {
		e.stopPropagation();

		try {
			if (!trackedElems.includes(e.target.tagName) && !isCustomTrackedElement(e.target)) return;

			const pageName = AnalyticsService.createPageName();
			const elementPath = getJSPath(e.target);
			let linkId = isCustomTrackedElement(e.target);

			if (!linkId) {
				const hashedPath = await hashElementPath(elementPath);
				linkId = hashedPath.slice(0, 8);
			}

			const event = {
				event_type: EVENT_NAMES.LINK_CLICK,
				device_id: getDeviceId(),
				event_payload: {
					aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
					link_info: {
						link_id: linkId,
						experiment_id: '', //  TODO: add once setup
						app_version: __APP_VERSION__, //eslint-disable-line
						element_path: elementPath,
						page_name: pageName,
						site_section: 'regpath'
					}
				}
			};
			return AnalyticsService.fireEvent({ event });
		} catch (e) {
			Sentry.captureException(e, {
				extra: {
					action: 'Error: Click Tracking Handler'
				}
			});
			console.error(e);
		}
	}

	/**
	 * Sets up the app navigation listeners to track visibility changes.
	 * @returns {Promise<void>} A promise that resolves when the listeners are set up.
	 */
	static async setAppNavigationListeners() {
		const visibilityChangeEvent =
			'onvisibilitychange' in document ? 'visibilitychange' : 'pagehide';
		document.addEventListener(visibilityChangeEvent, AnalyticsService.visibilityChangeHandler);
	}

	/**
	 * @returns {string} The created page name.
	 */
	static createPageName() {
		if (location.pathname === '/') return 'home';
		return location.pathname.replace(/^\//, '').replace(/\/$/, '').replace(/\//g, '-');
	}

	/**
	 * Sets up a click tracking listener on the document.
	 * @returns {void} A promise that resolves when the listener is set up.
	 */
	static setClickTrackingListener() {
		document.addEventListener('click', AnalyticsService.clickTrackingHandler);
	}

	/**
	 * Removes the app navigation listeners.
	 */
	static removeAppNavigationListeners() {
		const visibilityChangeEvent =
			'onvisibilitychange' in document ? 'visibilitychange' : 'pagehide';
		document.removeEventListener(visibilityChangeEvent, AnalyticsService.visibilityChangeHandler);
	}

	/**
	 * Removes the click tracking listener.
	 */
	static removeClickTrackingListener() {
		document.removeEventListener('click', AnalyticsService.clickTrackingHandler);
	}

	/**
	 * Tracks a page view event.
	 * @param {object} [options] - The optional options object.
	 * @param {string} [options.customPageName] - An optional custom page name.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackPageView(options = {}) {
		const { customPageName } = options;
		const pageName = customPageName ?? AnalyticsService.createPageName();
		const searchParams = new URLSearchParams(location.search);
		const params = {};
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		const event = {
			event_type: EVENT_NAMES.PAGE_LOAD,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				page_info: {
					site_section: 'regpath',
					page_name: pageName,
					page_url: location.origin + location.pathname,
					view_info: []
				},
				url_variable_info: params,
				referrer: ''
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * TEMPORARY
	 * Tracks a migration redirect event, for old system users
	 * @param {object} [payload] - The optional options object.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackMigrationRedirect(payload = {}) {
		const event = {
			event_type: EVENT_NAMES.MIGRATION_REDIRECT,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					experiment_id: '',
					app_version: __APP_VERSION__, //eslint-disable-line
					current_url: payload.currentUrl,
					user_type: payload.userType
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * TEMPORARY
	 * Tracks a migration redirect landing in the new system from the old system
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackMigrationLanding() {
		const queryParams = new URLSearchParams(location.search);

		const event = {
			event_type: EVENT_NAMES.MIGRATION_LANDING,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					user_to_product_id: queryParams.get('udp'),
					experiment_id: '',
					app_version: __APP_VERSION__, //eslint-disable-line
					landing_url: window.location.href,
					user_type: queryParams.get('user_type'),
					track_cookie: queryParams.get('track_cookie')
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * @returns {void}
	 */
	static thirdPartyPageLoad() {
		const page = AnalyticsService.createPageName();
		try {
			// Google Analytics
			if (window.gtag) {
				window.gtag('set', 'page', page);
				window.gtag('send', 'pageview');
			}

			// Bing UET
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'page_view', { page_path: page });
		} catch (err) {
			Sentry.captureException(err, {
				extra: {
					action: 'Error: Third Party Page Load'
				}
			});
		}
	}

	/**
	 * @param {string} string string to hash
	 * @returns {string}
	 */
	static async hashValue(string) {
		const utf8 = new TextEncoder().encode(string);
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}

	/**
	 * Sends a beacon with the provided data.
	 * @param {object} data - The data to be sent.
	 * @returns {boolean} - Returns true if the beacon was successfully sent, otherwise false.
	 */
	static async sendBeacon(data) {
		const payload = ApiService.pack(data);
		return navigator.sendBeacon(`/ws/amsl/0.1/json/Event/Log/init`, payload);
	}

	/**
	 * Pushes data to the dataLayer for analytics tracking.
	 * @param {object} data - The data to be pushed to the dataLayer.
	 * @param {string} data.eventName - The name of the event.
	 * @param {string} [data.product] - The product hash (optional).
	 * @param {string} [data.email] - The hashed email (optional).
	 */
	static async dataLayerPush(data) {
		try {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				id: uuidv4(),
				event: data?.eventName,
				eventData: {
					product: data?.product,
					timestamp: Date.now(),
					sourceTag: AnalyticsService.getSourceTag(),
					userAgent: navigator.userAgent,
					geo: get(app)?.meta?.viewerCountry,
					externalID: Cookies.get(COOKIES.CJ_COOKIE) || uuidv4(),
					hashedEMail: await AnalyticsService.hashValue(data?.email),
					pageUrl: location.href
				}
			});
		} catch (err) {
			Sentry.captureException(err, {
				extra: {
					action: 'Error: Data Layer Push'
				}
			});
		}
	}

	/**
	 * Retrieves the source tag from the URL search parameters or local storage.
	 * If a sourceTag exists, stores other query parameters in localStorage along with the tag itself.
	 * If legacySourceTag exists, checks the dynamic info cookie and stores in localStorage along with the tag itself.
	 * @returns {string|null} The source tag or null if not found.
	 */
	static getSourceTag() {
		const searchParams = new URLSearchParams(location.search);
		const sourceTag = searchParams.get(QUERY_PARAM_KEYS.SOURCE_TAG);

		const legacyCookiesNames = [
			COOKIES.LEGACY_CAMPAIGN_ID,
			COOKIES.LEGACY_CAMPAIGN_ID_TWO,
			COOKIES.LEGACY_CAMPAIGN_ID_THREE
		];

		let legacySourceTag = null;
		for (const cookieName of legacyCookiesNames) {
			const cookieValue = Cookies.get(cookieName);
			if (cookieValue) {
				legacySourceTag = cookieValue;
				break;
			}
		}
		const cachedSourceTag = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);

		if (sourceTag) {
			const queryParams = {};
			for (const [key, value] of searchParams.entries()) {
				queryParams[key] = value;
			}

			delete queryParams.src_tag;
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.SOURCE_INFO,
				JSON.stringify({
					id: sourceTag,
					values: queryParams
				})
			);
			return sourceTag;
		}

		if (legacySourceTag) {
			let sourceInfo = {};
			const dynamicInfoLegacy = Cookies.get(COOKIES.LEGACY_CAMPAIGN_VARS);

			if (dynamicInfoLegacy) {
				try {
					sourceInfo = JSON.parse(decodeURIComponent(dynamicInfoLegacy));
				} catch (err) {
					Sentry.captureException(err, {
						extra: {
							action: 'Error: Decoding Legacy Source Tag'
						}
					});
				}
			}
      
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.SOURCE_INFO,
				JSON.stringify({
					id: legacySourceTag,
					values: sourceInfo
				})
			);
			return legacySourceTag;
		}

		try {
			const tag = JSON.parse(cachedSourceTag);
			return tag?.id || null;
		} catch (err) {
			return cachedSourceTag || null;
		}
	}

	/**
	 * Attaches a tracking pixel to the document by creating an iframe and writing the pixel content into it.
	 * @param {string} pixel - The HTML content of the tracking pixel to be attached.
	 * @returns {Promise<void>} A promise that resolves when the iframe has successfully loaded the pixel.
	 */
	static attachPixel(pixel) {
		return new Promise((resolve, reject) => {
			const iFrame = document.createElement('iframe');
			iFrame.width = 1;
			iFrame.height = 1;
			iFrame.loading = 'lazy';
			iFrame.fetchpriority = 'low';
			iFrame.frameborder = 0;
			iFrame.style.display = 'none';

			document.body.appendChild(iFrame);

			iFrame.addEventListener('load', function () {
				resolve();
			});

			iFrame.contentWindow.document.open();
			iFrame.contentWindow.document.write(pixel);
			iFrame.contentWindow.document.close();
		});
	}

	/**
	 * Fires a campaign pixel if the global privacy control is not enabled.
	 * Retrieves campaign information from local storage and sends it to the API service.
	 * If a pixel is returned, it tracks and attaches the pixel.
	 * @param {string} event - The event name to be tracked.
	 * @returns {Promise<void>} - A promise that resolves when the pixel has been processed.
	 * @throws Will capture and log any exceptions using Sentry.
	 */
	static async fireCampaignPixel(event) {
		if (navigator.globalPrivacyControl) return;
		const campaignInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);
		if (campaignInfo) {
			const payload = {
				event,
				campaignInfo: JSON.parse(campaignInfo)
			};

			try {
				const response = await ApiService.resolvePixelInfo(payload);
				if (response && response.pixel) {
					AnalyticsService.trackPixel('before', event, 'campaign');
					await AnalyticsService.attachPixel(response.pixel);
					AnalyticsService.trackPixel('after', event, 'campaign');
				}
			} catch (e) {
				Sentry.captureException(e, {
					extra: {
						action: 'Error: Fire Campaign Pixel'
					}
				});
			}
		}
	}

	/**
	 * Tracks a pixel event by sending a payload to the analytics service.
	 * @param {string} action - The action associated with the pixel event.
	 * @param {string} event - The specific event to be tracked.
	 * @param {string} type - The type of the event.
	 * @returns {Promise<void>} - A promise that resolves when the event is successfully fired.
	 */
	static trackPixel(action, event, type) {
		const campaignId = AnalyticsService.getSourceTag();

		const payload = {
			event_type: `${EVENT_NAMES.PIXEL_FIRE}-${action}`,
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				event,
				type,
				campaignId
			}
		};

		return AnalyticsService.fireEvent({ event: payload });
	}

	/**
	 * Attribute the source tag to a campaign click event.
	 * @param {object|string} sourceTag - The source tag
	 * @returns {Promise<void>} - A promise that resolves when the event is tracked.
	 */
	static attributeSource(sourceTag) {
		const campaignInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);
		const dynamicInfo = campaignInfo ? JSON.parse(campaignInfo).values : {};
		const event = {
			event_type: EVENT_NAMES.CAMPAIGN_CLICK,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				source_info: {
					source_tag: sourceTag,
					dynamic_info: { ...dynamicInfo }
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * Tracks an event using the analytics service.
	 * @param {object} event - The event to track.
	 * @returns {Promise<void>} A promise that resolves when the event is tracked.
	 */
	static async fireEvent(event) {
		try {
			return ApiService.eventLog(event);
		} catch (e) {
			Sentry.captureException(e, {
				extra: {
					action: 'Error: Fire Event Log'
				}
			});
		}
	}

	/**
	 * Handles actions to be performed after navigation.
	 */
	static afterNavigate() {
		AnalyticsService.thirdPartyPageLoad();
		AnalyticsService.trackPageView();
		AnalyticsService.dataLayerPush({ eventName: 'global' });
	}

	/**
	 * Handles actions to be performed when the component is mounted.
	 */
	static async onMount() {
		const sourceTag = AnalyticsService.getSourceTag();
		if (sourceTag) {
			Sentry.setTag('source', sourceTag);
			AnalyticsService.attributeSource(sourceTag);
		}
		AnalyticsService.setAppNavigationListeners();
		AnalyticsService.setClickTrackingListener();
	}
}

export { AnalyticsService };
